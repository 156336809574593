import React from "react";

export default function One() {
    return (
        <>
        

      <section className="mt-[40px] md:mt-[50px] lg:mt-[50px] xl:mt-[50px] 2xl:mt-[50px]">
         <div className="">
             <h1 className="text-[#E4E8EB] text-[20px] text-center  lg:text-[25px] lg:mt-[30px] xl:text-[45px] xl:mt-[35px] 2xl:text-[45px] 2xl:mt-[40px]">Select the chat type that <span className="text-[#FFE269]">works your team.</span></h1>
             <p className="text-[#E4E8EB] text-center mt-[10px] text-[10px] font-light  md:text-[11px] md:!font-[500px] md:mx-auto  lg:text-[12px] xl:text-[20px] xl:leading-[35px] xl:w-6/12 2xl:w-5/12 2xl:text-[20px] 2xl:mt-[20px]">Choose the Ideal Chat Style for Seamless Collaboration and <br></br>Enhanced Productivity. Your Communication, Your Way</p>
         </div>
         <div className="mt-[20px]">
           <img src="https://dz1x1c630cl14.cloudfront.net/webassets/chatsimg.svg" className="w-10/12 mx-auto md:w-7/12 lg:w-7/12 xl:w-8/12 2xl:w-8/12" alt="noimg"/>
         </div>
      </section>

     
      <section className="p-[20px] mt-[40px] md:mt-[50px] lg:mt-[50px] xl:mt-[50px] 2xl:mt-[50px]">
         <div className="roundb">
         <div className="grid grid-cols-1 mx-auto md:grid-cols-2 md:w-10/12 lg:w-9/12 2xl:w-8/12">
            <div>
               <h1 className="text-[#E4E8EB] text-center sm:text-left lg:text-[18px] xl:text-[32px] 2xl:text-[35px]"><span className="text-[#FFE269]">No need to wait</span> for the right<br></br> moment, Choose when to send <br></br>in <span className="text-[#FFE269]">scheduled chats</span></h1>
               <p className="text-[#E4E8EB] text-center text-[12px] sm:text-left xl:text-[20px]">Schedule chats effortlessly, sending messages<br></br> at your chosen time. Don't wait; make every <br></br> moment count.</p>
            </div>

            <div>
               <img src="https://dz1x1c630cl14.cloudfront.net/webassets/typechat.svg" className="" alt=""/>
            </div>
         
         </div>
         </div>
        
      </section> 

     

     

       <section className="mt-[40px] md:mt-[50px] lg:mt-[50px] xl:mt-[50px] 2xl:mt-[50px]">
           <div>
            <h1 className="text-center DarkerGrostesque font-[600px] text-[#E4E8EB] text-[25px] md:text-[45px] lg:text-[55px] xl:text-[70px] 2xl:text-[75px]">
                     1.ai
            </h1>
            <p className="text-[#E4E8EB] text-center mt-[10px] font-[600px] md:mt-[25px] lg:text-[20px] lg:mt-[30px] xl:text-[26px] xl:mt-[35px] 2xl:text-[30px] 2xl:mt-[40px]">Every words are useful, <span className="text-[#FFE269]">Chat and grow together</span> with your team</p>
         </div>
         <img src="https://dz1x1c630cl14.cloudfront.net/webassets/Ellipsed.svg" alt="ellipsed" className="z-[0] hidden sm:block md:absolute md:top-[1000px] md:w-[500px] md:left-[50px] lg:absolute lg:top-[1300px] lg:w-[600px] lg:left-[60px] xl:absolute xl:w-[600px] xl:top-[2000px] xl:left-[300px]  2xl:absolute 2xl:w-[1200px] 2xl:top-[2300px] 2xl:left-[500px]"/>
         <div className="grid gris-cols-1  md:grid-cols-2 md:w-10/12 mx-auto lg:mt-[30px] lg:w-10/12 lg:mx-auto 2xl:w-8/12 2xl:mx-auto p-[20px]">
               <div className="mt-[10px]">
                  <h1 className="text-[#E4E8EB] text-center text-[18px] sm:text-left leading-[25px] md:text-[18px] md:leading-[24px] lg:text-[24px] lg:leading-[30px] xl:text-[38px] xl:leading-[48px] 2xl:text-[50px] 2xl:leading-[50px]">Categorized chat<br></br><span className="text-[#FFE269]"> So, We never missed</span></h1>
                 
                  <div className="items-center justify-center mt-[20px] md:mt-[25px] lg:mt-[30px] xl:mt-[60px]">
                    <div className="flex  mt-[10px] xl:mt-[20px]">
                      <img src="https://dz1x1c630cl14.cloudfront.net/webassets/dots.svg"  className="" alt="noimg"/>
                      <h1 className="text-[#E4E8EB] px-[10px] text-[15px] xl:text-[20px] 2xl:text-[25px]">Real time communicate</h1>
                    </div>
                    <div className="flex  mt-[5px] md:mt-[8px]">
                      <img src="https://dz1x1c630cl14.cloudfront.net/webassets/dots.svg"  className="" alt="noimg"/>
                      <h1 className="text-[#E4E8EB] px-[10px] text-[15px] xl:text-[20px] 2xl:text-[25px]">Share & upload multiple files</h1>
                    </div>
                    <div className="flex  mt-[5px] md:mt-[8px]">
                      <img src="https://dz1x1c630cl14.cloudfront.net/webassets/dots.svg"  className="" alt="noimg"/>
                      <h1 className="text-[#E4E8EB] px-[10px]  text-[15px] xl:text-[20px 2xl:text-[25px]">Quick search</h1>
                    </div>
                    <div className="flex  mt-[5px] md:mt-[8px]">
                      <img src="https://dz1x1c630cl14.cloudfront.net/webassets/dots.svg"  className="" alt="noimg"/>
                      <h1 className="text-[#E4E8EB] px-[10px]  text-[15px] xl:text-[20px] 2xl:text-[25px]">Connect virtually</h1>
                    </div>
                  </div>
               </div>
               <div className="mt-[20px]">
                  <img src="https://dz1x1c630cl14.cloudfront.net/webassets/chatimg.svg" className="" alt="noimg"/>
               </div>
            </div>
       </section>
       
       {/* public private */}
       <div className="p-[10px] md:p-[30px] lg:p-[50px]  2xl:mt-[60px]">
            <div className="mt-[40px]">
             
                 <p className="text-[#E4E8EB] text-[15px] text-center md:text-[15px] lg:text-[18px] xl:text-[32px]">A place to unite your people and  <br></br> <span className="text-[#FFE269]"> make that place yourself</span></p>
                 <p className="text-[#E4E8EB] text-center mt-[10px] text-[13px] font-light  md:text-[13px] md:w-8/12 md:!font-[500px] md:mx-auto lg:w-7/12 lg:text-[16px] xl:text-[20px] xl:leading-[35px] xl:w-6/12 2xl:w-5/12 2xl:text-[26px] 2xl:mt-[20px]">Create a space for your community to come together. Build it yourselves, making a place that reflects the unity and identity of your people.</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-[20px] md:w-11/12 md:mx-auto lg:w-11/12 lg:mx-auto xl:w-10/12 2xl:w-7/12 2xl:gap-[30px]">
               <div className="mt-[20px] md:mt-[30px] lg:mt-[40px] xl:mt-[50px]">
                  <img src="https://dz1x1c630cl14.cloudfront.net/webassets/public.svg" alt="publics" className="2xl:w-full"/>
               </div>

               <div className="mt-[20px] md:mt-[30px] lg:mt-[40px] xl:mt-[50px]">
                  <img src="https://dz1x1c630cl14.cloudfront.net/webassets/private.svg" alt="privates" className="2xl:w-full"/>
               </div>
               <div className="hidden border-[0.89px] w-full h-[240px] rounded-[30px] mt-[10px] borders">
                 <div className="border-[0.89px] w-full h-[220px] rounded-[30px] mt-[20px] p-[20px] seclenear xl:w-full xl:h-[350px] 2xl:w-full 2xl:h-[400px]">
                  <h1 className="text-[#E4E8EB] text-center mt-[5px] text-[13px] DarkerGrostesque font-light xl:text-[24px] 2xl:text-[26px]">Collective solutions from </h1>
                  <h1 className="bg-linear text-center text-[26px] font-[500px] xl:text-[43.3px] 2xl:text-[45px]">Public channel</h1>
                   
                  <img src="https://dz1x1c630cl14.cloudfront.net/webassets/onebutton.svg" alt="onebutton" className="w-[200px] h-[90px] mx-auto  xl:mt-[20px]"/>
                  <p className="text-[#E4E8EB] text-center DarkerGrostesque font-[500px] text-[8.5px] xl:text-[17px]  xl:mt-[30px] 2xl:text-[24px]">Foster Transparency and Unity - Engage in Team Conversations,<br></br> Share Ideas, and Achieve Common Goals</p>
                  </div>
               </div>

               <div className="hidden border-[0.89px] w-full h-[240px] rounded-[30px] mt-[10px] borders">
                 <div className="border-[0.89px] w-full h-[220px] rounded-[30px] mt-[20px] p-[20px] seclenear  xl:w-full xl:h-[350px] 2xl:w-full 2xl:h-[400px]">
                  <h1 className="text-[#E4E8EB] text-center mt-[5px] text-[13px] DarkerGrostesque font-light xl:text-[24px]">Confidential conversations in  </h1>
                  <h1 className="yellowlinear text-center text-[26px] font-[500px] xl:text-[43.3px]">Private channel</h1>
                   
                  <img src="https://dz1x1c630cl14.cloudfront.net/webassets/twobutton.svg" alt="twobutton" className="w-[200px] h-[90px] mx-auto xl:mt-[20px]"/>
                  <p className="text-[#E4E8EB] text-center DarkerGrostesque font-[500px] text-[8.5px] xl:text-[17px] xl:mt-[30px]  2xl:text-[24px]">Empower Collaboration with Confidentiality - Your Private<br></br> Hub for Team Discourse</p>
                  </div>
               </div>

              
            </div>
            {/* <img src={vector1} className="md:w-[1200px] md:h-[200px]  md:relative md:bottom-[90px] xl:relative xl:bottom-[35px] xl:left-[45px] xl:w-[1260px] 2xl:w-[1785px] 2xl:h-[515px] 2xl:relative 2xl:left-[200px] 2xl:bottom-[90px] 2xl:hidden" alt="noimg"/> */}
       </div>
        
        
        
        </>
    )
}