import React from 'react'

export default function Management() {
  return (
    <div className="flex flex-col items-center relative w-10/12 mx-auto mt-[100px]">
        <h2 className='text-center text-[40px] font-semibold bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent'>Systematic Ready Solution For all enterprises to manage & govern any projects.</h2>
        <div className='flex gap-5 items-center overflow-x-scroll w-full mt-[50px]'>
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/ceo.svg" alt='ceo' className='flex shrink-0'/>
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/sales.png" alt='ceo' className='flex shrink-0'/>
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/manager.svg" alt='ceo' className='flex shrink-0'/>
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/designer.svg" alt='ceo' className='flex shrink-0'/>
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/finance.svg" alt='ceo' className='flex shrink-0'/>
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/operation.svg" alt='ceo' className='flex shrink-0'/>
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/administration.svg" alt='ceo' className='flex shrink-0'/>
          <img src="https://dz1x1c630cl14.cloudfront.net/webassets/hr.svg" alt='ceo' className='flex shrink-0'/>
        </div>
    </div>
  )
}
