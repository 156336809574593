import React from 'react'

export default function Refund() {
  return (
    <div className='w-full h-screen relative p-10'>
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/Glowing.svg" alt='glow' className='fixed w-full h-full top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-0'/> 
        <h1 className='DarkerGrostesqueSemibold text-[30px] sm:text-[40px] lg:text-[80px] bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent text-center z-[1]'>Cancellation & Refund Policy</h1>
        <h3 className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-bold mt-[30px]'>Last updated on 01-03-2024 15:35:26</h3>
        <h3 className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-semibold mt-[30px] z-10 relative'><span className='bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent'>1.Ai</span> believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:</h3>
        <h4 className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-normal mt-[30px] z-10 relative'><span className='text-[#FDD320]'>•</span> Cancellations will be considered only if the request is made immediately after placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.</h4>
        <h4 className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-normal mt-[30px] z-10 relative'><span className='text-[#FDD320]'>•</span> <span className='bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent'>1.Ai</span> does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.</h4>
        <h4 className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-normal mt-[30px] z-10 relative'><span className='text-[#FDD320]'>•</span> In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within 7 Days days of receipt of the products. In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 7 Days days of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.</h4>
        <h4 className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-normal mt-[30px] z-10 relative'><span className='text-[#FDD320]'>•</span> In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them. In case of any Refunds approved by the <span className='bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent'>1.Ai</span>, it’ll take 3-5 Days days for the refund to be processed to the end customer.</h4>
    </div>
  )
}