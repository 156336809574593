import React from 'react'

export default function PreferTools() {
  return (
    <div className="relative w-10/12 mx-auto mt-[100px]">
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/arrow.svg" alt="arrow" className='absolute right-0 top-[150px]'/>
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/glow3.svg" alt="arrow" className='absolute -right-[100px] -top-[140px] w-[500px] h-[900px]'/>
        <h2 className='text-center text-[45px] font-semibold bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent'>When you can manage complex takes easily with 1.ai, why would you still prefer during multiple tools to run your business?</h2>
        <div className='mt-[60px] p-10  bg-[#0F162F80] border-[1px] border-[#E2E8FF1A] rounded-[16px] max-w-[650px] mx-[80px]'>
            <div className='flex gap-6 items-center mx-[60px]'>
                <div className='w-[25px] h-[25px] rounded-[8px] p-[2px] bg-gradient-to-b from-[#AEA65D] to-[#B0ADAD00]'>
                    <div className='w-full h-full bg-[#D8B10F91] rounded-[6px]'></div>
                </div>
                <h3 className='inter-normal  text-[#E4E8EB] text-[24px]'>No More Excel Sheets</h3>
            </div>
            <div className='flex gap-6 items-center mx-[60px] mt-5'>
                <div className='w-[25px] h-[25px] rounded-[8px] p-[2px] bg-gradient-to-b from-[#AEA65D] to-[#B0ADAD00]'>
                    <div className='w-full h-full bg-[#D8B10F91] rounded-[6px]'></div>
                </div>
                <h3 className='inter-normal  text-[#E4E8EB] text-[24px]'>No more Word Docs </h3>
            </div>
            <div className='flex gap-6 items-center mx-[60px] mt-5'>
                <div className='w-[25px] h-[25px] rounded-[8px] p-[2px] bg-gradient-to-b from-[#AEA65D] to-[#B0ADAD00]'>
                    <div className='w-full h-full bg-[#D8B10F91] rounded-[6px]'></div>
                </div>
                <h3 className='inter-normal  text-[#E4E8EB] text-[24px]'>No more Miscommunication</h3>
            </div>
        </div>
    </div>
  )
}
