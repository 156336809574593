import React from 'react'


export default function Migration() {

    return (
        <div className="flex flex-col items-center relative w-10/12 mx-auto mt-[100px]">
            <h3 className='text-center text-[60px] font-semibold bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent'>Integration & Migration</h3>
            <h4 className='mt-[10px] text-[#E4E8EB] font-medium text-[34px]'>Your Data from any software in one click</h4>
            <div className='relative w-full'>
                <div className='relative scale1'>
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/circle_1.svg" alt="circle" className='mx-auto' />
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/circle_2.svg" alt="circle" className='absolute left-[49.5%] -translate-x-[50%] top-[49%] -translate-y-[50%]' />
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/circle_3.svg" alt="circle" className='absolute left-[49.5%] -translate-x-[50%] top-[49%] -translate-y-[50%]' />
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/circle_4.svg" alt="circle" className='absolute left-[49.5%] -translate-x-[50%] top-[49%] -translate-y-[50%]' />
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/circle_5.svg" alt="circle" className='absolute left-[49%] -translate-x-[50%] top-[49%] -translate-y-[50%]' />
                </div>
                <img src="https://dz1x1c630cl14.cloudfront.net/webassets/icon.svg" alt='logo' className='absolute left-[49%] -translate-x-[50%] top-[49%] -translate-y-[50%] scale2' />
                {/* <img src='https://dz1x1c630cl14.cloudfront.net/webassets/integration.gif' alt='gif'/> */}
            </div>
        </div>
    )
}
